.container {
  background-color: #f6f7f9;
  padding: 0 21px 50px;
  &.isMobile {
    padding: 0 21px;
    .stepTitleWrap {
      padding: 21px;
      width: 100%;
      transform: translateX(-21px);
      background-color: #fff;
    }
    .orderTitleWrap {
      padding: 30px 21px 12px 21px;
      width: 100%;
      transform: translateX(-21px);
      background: #fff;
    }
    .productListWrap {
      padding: 10px 0 23px 0;
      border: none;
      background: transparent;
      &.isHealthListWrap {
        padding: 23px 0;
      }
    }
    .couponPrescWrap {
      text-align: start;
      padding: 16px 0;
      background: #f6f7f9;
      border: none;
    }
    .discountInfoWrap {
      margin: 0;
      padding: 21px 0 21px 24px;
      border: none;
      background: #f6f7f9;
      &.subDiscountInfoWrap {
        padding: 21px 0;
      }
    }
    .payMethodWrap {
      padding: 21px 0;
      border: none;
      background: #f6f7f9;
    }
    .agreementList {
      padding: 16px 0;
    }
    .buyBtnArea {
      background: #fff;
      display: flex;
      align-items: center;
      width: calc(100% + 42px);
      height: 72px;
      transform: translateX(-21px);
      .buyBtnWrap {
        width: calc(100% - 42px);
        transform: translateX(21px);
        &.fixedBtn {
          position: relative;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
    }
  }
  .orderTitleWrap {
    padding: 30px 0 12px 0;
    display: flex;
    align-items: center;
    gap: 4px;
    &.couponTitleWrap {
      justify-content: space-between;
      & > span {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #666;
      }
      & > div {
        display: flex;
        align-items: center;
        gap: 4px;
        & > span {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
    img {
      width: 20px;
      height: 20px;
      aspect-ratio: 1/1;
    }
    & > span {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .couponPrescWrap {
    padding: 20px 0;
    text-align: center;
    background: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
    span:nth-child(2) {
      font-weight: 600;
    }
  }
}
.prevWrap {
  padding: 18px 21px;
  width: 100%;
  transform: translateX(-21px);
  background: #fff;
  img {
    width: 24px;
    height: 24px;
  }
}
.stepTitleWrap {
  padding: 21px 0;
  display: flex;
  justify-content: space-between;
  & > span {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
  }
  .stepWrap {
    display: flex;
    align-items: center;
    gap: 8px;
    & > span {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #999;
    }
    & > div {
      position: relative;
      content: '';
      width: 8px;
      height: 8px;
      background: #d9d9d9;
      border-radius: 50%;
    }
    .hitStep {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      font-weight: 500;
      font-size: 10px;
      color: #fff;
      background: #131c31;
    }
  }
}
.header {
  height: 105px;
  display: flex;
  align-items: center;
  gap: 4px;
  img {
    width: 21px;
    height: 21px;
    cursor: pointer;
  }
  span {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: #232323;
    padding: 10px;
  }
}
:global(.pc) .header span {
  font-size: 24px;
  line-height: 29px;
}

.productListWrap {
  padding: 5px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: white;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  &.isHealthListWrap {
    padding: 16px;
  }
  .payBannerWrap {
    img {
      width: 100%;
      aspect-ratio: 395/171;
    }
  }
  .productDropWrap {
    box-sizing: border-box;
    border: 1px solid #d9d9d9;
    background: #fff;
    border-radius: 10px;
    &.hit {
      border: 2px solid #ff7f00;
      background: #fff3e7;
      .productDropTitleWrap {
        .payStepWrap {
          color: #fff;
          background: #ff7f00;
        }
      }
      .productDropInfoWrap {
        .totalPriceWrap {
          background: #ffe6cd;
        }
      }
    }
    .productDropInfoWrap {
      padding: 0 16px;
    }
    .productDropTitleWrap {
      position: relative;
      padding: 0 16px 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 64px;
      cursor: pointer;
      .payStepWrap {
        position: absolute;
        top: -8px;
        padding: 2px 8px;
        font-weight: 400;
        font-size: 10px;
        border-radius: 4px;
        color: #666;
        background: #d9d9d9;
      }
      .categoryWrap {
        display: flex;
        gap: 4px;
        & > span {
          font-weight: 500;
          font-size: 17px;
          line-height: 24px;
        }
        span:last-child {
          color: #ff7f00;
        }
      }
      .priceWrap {
        & > span {
          font-weight: 600;
          font-size: 17px;
          line-height: 24px;
          color: #ff7f00;
        }
        .payEndSpan {
          color: #ff5656;
        }
        img {
          width: 24px;
          height: 24px;
          aspect-ratio: 1/1;
        }
      }
    }
    .productInfoWrap {
      padding: 16px 0;
      display: flex;
      gap: 8px;
      &:first-child {
        border-top: 1px solid #d9d9d9;
      }
      .thumbnailWrap {
        width: 60px;
        height: 60px;
        border-radius: 5px;
        overflow: hidden;
        flex-shrink: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .productInfo {
        width: calc(100% - 68px);
        .productName {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: var(--theme-font-color);
        }
        .optionList {
          li {
            display: flex;
            gap: 2px;
            span {
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              color: #666;
            }
            span:first-child {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            span:last-child {
              width: 20px;
              color: #131c31;
            }
          }
        }
        .totalInfoWrap {
          display: flex;
          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #666;
          }
          span:last-child {
            color: #131c31;
          }
        }
      }
    }
  }
  .totalPriceWrap {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background: #f6f7f9;
    margin-bottom: 16px;
    & > div {
      display: flex;
      justify-content: space-between;
      & > span {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }
      & > span:first-child {
        color: #666;
      }
      & > span:last-child {
        color: #131c31;
      }
    }
    .totalWrap {
      & > span {
        font-weight: 600;
      }
      & > span:first-child {
        color: #131c31;
      }
    }
  }
}

.ordererInfoWrap {
  padding: 20px 15px 40px;
  background-color: white;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-top: 10px;
  .sectionHeader {
    padding: 10px 0px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: var(--theme-font-color);
    margin-bottom: 10px;
  }
}
.questionnaireWrap {
  margin-top: 10px;
  padding: 20px 15px;
  background-color: white;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  .sectionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    & > span:first-child {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.5px;
      color: var(--theme-font-color);
    }
    label {
      display: flex;
      align-items: center;
      gap: 5.5px;
      padding: 13px 0;
      cursor: pointer;
      input[type='checkbox'] {
        -webkit-appearance: none;
        appearance: none;
        width: 15px;
        height: 15px;
        border: 1.5px solid #6e6d79;
        border-radius: 3px;
        &.checked {
          background-color: #ff7f00;
          border: 1.5px solid #ff7f00;
          position: relative;
          &::before {
            content: '';
            display: block;
            width: 5px;
            height: 7px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            position: absolute;
            left: 2.5px;
            top: 0px;
          }
        }
      }
      span {
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        color: var(--theme-font-color);
      }
    }
  }
  .questionnaireForm {
    .phoneNumberLabel {
      .labelWrap {
        padding: 11px 0;
        display: flex;
        gap: 1px;
        & > span:first-child {
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: #ff5656;
        }
        & > span:last-child {
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: rgba(19, 28, 49, 0.5);
        }
      }
      div:last-child {
        .phoneNumberInuptWrap {
          // gap: 17px;
          position: relative;
          span {
            width: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: rgba(19, 28, 49, 0.5);
          }
          input {
            background: #ffffff;
            border: 1px solid #e5e5e5;
            border-radius: 5px;
            width: 100%;
            height: 48px;
            box-sizing: border-box;
            padding-left: 10px;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: var(--theme-font-color);
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            /* Firefox */
            &[type='number'] {
              -moz-appearance: textfield;
            }
          }
        }
        p {
          font-weight: 500;
          font-size: 11px;
          line-height: 13px;

          color: rgba(19, 28, 49, 0.5);
          padding: 7px 0;
        }
      }
    }
    .securityNumberLabel {
      .labelWrap {
        padding: 11px 0;
        display: flex;
        gap: 1px;
        & > span:first-child {
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: #ff5656;
        }
        & > span:last-child {
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: rgba(19, 28, 49, 0.5);
        }
      }
      div:last-child {
        .securityNumberInuptWrap {
          display: flex;
          align-items: center;
          justify-content: space-between;
          // gap: 17px;
          position: relative;
          span {
            width: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: rgba(19, 28, 49, 0.5);
          }
          input {
            background: #ffffff;
            border: 1px solid #e5e5e5;
            border-radius: 5px;
            height: 48px;
            flex-grow: 1;
            max-width: calc(50% - 8px);
            box-sizing: border-box;
            padding-left: 10px;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: var(--theme-font-color);
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            /* Firefox */
            &[type='number'] {
              -moz-appearance: textfield;
            }
          }
        }
        p {
          font-weight: 500;
          font-size: 11px;
          line-height: 13px;

          color: rgba(19, 28, 49, 0.5);
          padding: 7px 0;
        }
      }
    }
  }
  .pregnancyLabel {
    margin-top: 20px;
    display: flex;
    align-items: center;
    &.warning > div:first-child > span:last-child {
      color: #ff5656;
    }
    & > div:first-child {
      padding: 15px 0;
      display: flex;
      gap: 1px;
      margin-right: 30px;
      & > span:first-child {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #ff5656;
      }
      & > span:last-child {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: rgba(19, 28, 49, 0.5);
      }
    }
    .customCheckBoxWrap {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 0px;
      gap: 7px;
      cursor: pointer;
      flex-grow: 1;
      max-width: 106px;
      .customCheckBox {
        width: 18px;
        height: 18px;
        border: 1.5px solid #e5e5e5;
        border-radius: 50%;
        position: relative;
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #e5e5e5;
        }
      }
      span {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: rgba(19, 28, 49, 0.5);
      }
      &.hit {
        .customCheckBox {
          background: #ff5656;
          border: 1.5px solid #ff5656;
          &::after {
            background-color: white;
          }
        }
        span {
          color: var(--theme-font-color);
        }
      }
    }
  }
}
.prescriptionDetailWrap {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-top: 10px;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .label {
    display: flex;
    flex-direction: column;
    align-items: unset !important;
    &.warning {
      .labelWrap > span:last-child {
        color: #ff5656;
      }
    }
    .labelWrap {
      // width: 93px;
      width: 100% !important;
    }
    &.caffeineSensitivity {
      .checkboxArea {
        .customCheckBoxWrap {
          // width: 33.3%;
        }
      }
    }
    .sideEffects {
      width: 100%;
      height: 200px;
      box-sizing: border-box;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      resize: none;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: var(--theme-font-color);
    }
    .checkboxArea {
      display: flex;
      justify-content: space-between;
      max-width: 334px;
      .customCheckBoxWrap {
        display: flex;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        // max-width: 83px;
        gap: 7px;
        &.hit {
          .customCheckBox {
            background: #ff5656;
            border: 1.5px solid #ff5656;
            &::after {
              background-color: white;
            }
          }
          span {
            color: var(--theme-font-color);
          }
        }
        .customCheckBox {
          width: 18px;
          height: 18px;
          border: 1.5px solid #e5e5e5;
          border-radius: 50%;
          position: relative;
          &::after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #e5e5e5;
          }
        }
        span {
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: rgba(19, 28, 49, 0.5);
        }
      }
    }
  }
}
.diseaseCheckWrap,
.medicineCheckWrap {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-top: 10px;
  padding: 0 15px;
  &.warning {
    border: 1px solid #ff5656;
    .checkArea > div:first-child > span:last-child {
      color: #ff5656;
    }
  }
  .checkArea {
    display: flex;
    align-items: center;
    height: 88px;
    & > div:first-child {
      padding: 15px 0;
      display: flex;
      gap: 1px;
      width: 93px;
      & > span:first-child {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #ff5656;
      }
      & > span:last-child {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: rgba(19, 28, 49, 0.5);
      }
    }
    .customCheckBoxWrap {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 0px;
      gap: 7px;
      cursor: pointer;
      flex-grow: 1;
      max-width: 106px;
      .customCheckBox {
        width: 18px;
        height: 18px;
        border: 1.5px solid #e5e5e5;
        border-radius: 50%;
        position: relative;
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #e5e5e5;
        }
      }
      span {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: rgba(19, 28, 49, 0.5);
      }
      &.hit {
        .customCheckBox {
          background: #ff5656;
          border: 1.5px solid #ff5656;
          &::after {
            background-color: white;
          }
        }
        span {
          color: var(--theme-font-color);
        }
      }
    }
  }
  .sectionGuide {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: rgba(19, 28, 49, 0.5);
    display: inline-block;
    padding: 15px 0;
  }
  textarea {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 13px 8px;
    width: 100%;
    height: 170px;
    box-sizing: border-box;
    resize: none;
    margin-bottom: 20px;
    &::placeholder {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: rgba(19, 28, 49, 0.2);
    }
  }
}

.methodInfoWrap {
  margin-top: 10px;
  padding: 20px 15px 40px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: #131c31;
    padding: 10px 0;
    margin-bottom: 10px;
  }
  .customCheckBoxList {
    display: flex;
    flex-wrap: wrap;
    .customCheckBoxWrap {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 10px 10px 0px 10px;
      cursor: pointer;
      &.hit {
        .customCheckBox {
          background: #ff5656;
          border: 1.5px solid #ff5656;
          &::after {
            background-color: white;
          }
        }
        span {
          color: #131c31;
        }
      }
      span {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: rgba(19, 28, 49, 0.5);
      }
      .customCheckBox {
        width: 18px;
        height: 18px;
        background: white;
        border: 1.5px solid #e5e5e5;
        border-radius: 50%;
        position: relative;
        &::after {
          content: '';
          display: block;
          width: 8px;
          height: 8px;
          background: #e5e5e5;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

.deliveryInfoWrap {
  margin-top: 10px;
  padding: 20px 15px 40px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: var(--theme-font-color);
    padding: 10px 0;
    margin-bottom: 10px;
  }
  form {
    .addressTypeWrap {
      & > div:first-child {
        display: flex;
        gap: 1px;
        padding: 11px 17px 11px 0;
        box-sizing: border-box;
        flex-shrink: 0;
        span:first-child {
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: #ff5656;
        }
        span:last-child {
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: rgba(19, 28, 49, 0.5);
        }
      }
      .customCheckBoxList {
        display: flex;
        flex-wrap: wrap;
        & > p {
          font-weight: 500;
          font-size: 11px;
          line-height: 13px;
          color: rgba(19, 28, 49, 0.5);
          padding: 7px 0 20px;
          width: 100%;
        }
        .customCheckBoxWrap {
          display: flex;
          align-items: center;
          gap: 4px;
          padding: 11px 10px;
          cursor: pointer;
          &.hit {
            .customCheckBox {
              background: #ff5656;
              border: 1.5px solid #ff5656;
              &::after {
                background-color: white;
              }
            }
            span {
              color: var(--theme-font-color);
            }
          }
          span {
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            color: rgba(19, 28, 49, 0.5);
          }
          .customCheckBox {
            width: 18px;
            height: 18px;
            background: white;
            border: 1.5px solid #e5e5e5;
            border-radius: 50%;
            position: relative;
            &::after {
              content: '';
              display: block;
              width: 8px;
              height: 8px;
              background: #e5e5e5;
              border-radius: 50%;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
    .addressWrap {
      .addressLabel {
        padding: 11px 0;
        display: flex;
        gap: 1px;
        span:first-child {
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: #ff5656;
        }
        span:last-child {
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: rgba(19, 28, 49, 0.5);
        }
      }
      .zipCode {
        display: flex;
        .inputWrap {
          flex-grow: 1;
          display: flex;
          width: calc(100% - 106px);
          input {
            flex-grow: 1;
            height: 48px;
            box-sizing: border-box;
            background: #ffffff;
            border: 1px solid #e5e5e5;
            border-radius: 5px 0 0 5px;
            padding-left: 10px;
            color: var(--theme-font-color);
            font-size: 15px;
            font-weight: 400;
            height: 48px;
            line-height: 18px;
            &::placeholder {
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;

              color: rgba(19, 28, 49, 0.2);
            }
          }
        }
        .addressSearch {
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: #e5e5e5;
          width: 106px;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--theme-color);
          border-radius: 0px 5px 5px 0px;
          cursor: pointer;
          flex-shrink: 0;
          position: relative;
        }
      }
      .label {
        display: block;
        margin-top: 10px;
      }
    }
    .deliveryMemo {
      .labelWrap {
        gap: 0;
      }
    }
  }
}
.priceGuideList {
  padding: 16px 21px;
  li {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: -0.2px;
    color: #999;
  }
}
.mPriceGuideList {
  width: 100%;
  transform: translateX(-21px);
  background: #fff;
}
.totalPriceInfoWrap {
  padding: 16px 16px 16px 24px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  .sectionHeader {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: var(--theme-font-color);
    padding: 10px 0;
    margin-bottom: 20px;
  }
  .productPrice {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > span:first-child {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.5px;
      color: rgba(19, 28, 49, 0.5);
    }
    & > span:last-child {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: var(--theme-font-color);
    }
  }
  .discountPrice {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > span:first-child {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.5px;
      color: rgba(19, 28, 49, 0.5);
    }
    & > span:last-child {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: var(--theme-font-color);
    }
  }
  .deliveryPrice {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    & > span:first-child {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.5px;
      color: rgba(19, 28, 49, 0.5);
    }
    & > span:last-child {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: var(--theme-font-color);
    }
  }
  .totalPrice {
    padding-top: 15px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > span:first-child {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.5px;
      color: rgba(19, 28, 49, 0.5);
      padding: 12.5px 10px;
    }
    & > span:last-child {
      font-weight: 700;
      font-size: 21px;
      line-height: 25px;
      color: var(--theme-font-color);
      padding: 9px 5px;
    }
  }
}
.discountInfoWrap {
  margin-top: 10px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 25px 15px;
  &.subDiscountInfoWrap {
    padding: 21px 0;
  }
  .sectionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    span {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: var(--theme-font-color);
      padding: 10px 0;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
  .content {
    display: flex;
    gap: 8px;
    flex-direction: column;
    .noDiscountWrap {
      text-align: center;
      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
      .hit {
        font-weight: 600;
      }
    }
    .savings {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      & > div:first-child {
        min-width: 77px;
        width: 77px;
        span:first-child {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: var(--theme-font-color);
        }
      }
      & > div:last-child {
        display: flex;
        // 타이틀77 + gap10
        width: calc(100% - 87px);
        input {
          background: #ffffff;
          border: 1px solid #d9d9d9;
          border-radius: 5px 0 0 5px;
          padding-left: 10px;
          box-sizing: border-box;
          width: 100%;
          height: 38px;
          flex-grow: 1;
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          /* Firefox */
          &[type='number'] {
            -moz-appearance: textfield;
          }
        }
        input::placeholder {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #999;
        }
        div {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--theme-color);
          border-radius: 0px 5px 5px 0px;
          min-width: 60px;
          width: 60px;
          height: 38px;
          cursor: pointer;
        }
      }
    }
    .couponBtn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      & > div:first-child {
        width: 77px;
        span:first-child {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #131c31;
        }
      }
      & > div:last-child {
        padding: 9px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 87px);
        height: 38px;
        box-sizing: border-box;
        border: 1px solid #d9d9d9;
        background: #fff;
        border-radius: 5px;
        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #999;
          &.hit {
            color: #131c31;
          }
        }
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
    .promotion {
      display: flex;
      gap: 10px;
      justify-content: space-between;
      align-items: center;
      & > div:first-child {
        min-width: 77px;
        width: 77px;
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: var(--theme-font-color);
        }
      }
      & > div:last-child {
        display: flex;
        width: calc(100% - 87px);
        input {
          background: #ffffff;
          border: 1px solid #d9d9d9;
          border-radius: 5px 0 0 5px;
          padding-left: 10px;
          box-sizing: border-box;
          width: 100%;
          height: 38px;
          flex-grow: 1;
        }
        input::placeholder {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #999;
        }
        div {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--theme-color);
          border-radius: 0px 5px 5px 0px;
          min-width: 60px;
          width: 60px;
          height: 38px;
          cursor: pointer;
        }
      }
    }
  }
}
.payMethodWrap {
  padding: 16px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  .sectionHeader {
    padding: 10px 0;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: var(--theme-font-color);
  }
  .payMethodList {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    li {
      display: flex;
      align-items: center;
      padding: 20px 16px;
      flex-grow: 1;
      box-sizing: border-box;
      width: calc(100% / 2 - 8px);
      height: 60px;
      border-radius: 10px;
      border: 1px solid #d9d9d9;
      box-sizing: border-box;
      cursor: pointer;
      &.hit {
        border: 2px solid #ff7f00;
        background: #fff3e7;
        .customCheckBox {
          background: #fff;
          border: 1.5px solid #ff7f00;
          &::after {
            background-color: #ff7f00;
          }
        }
        span {
          color: var(--theme-font-color);
        }
      }
      gap: 12px;
      .customCheckBox {
        border: 1.5px solid #e5e5e5;
        box-sizing: border-box;
        width: 16px;
        height: 16px;
        position: relative;
        border-radius: 50%;
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }
      }
      span {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: rgba(19, 28, 49, 0.5);
      }
    }
    .payAlert {
      padding: 16px 0 12px 0;
      width: 100%;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      color: #131c31;
    }
    .payIconWrap2 {
      img:last-child {
        width: 101px;
        height: 12px;
      }
    }
    .payIconWrap {
      display: flex;
      gap: 12px;
      max-width: 100%;
      & > div {
        display: flex;
        flex-direction: column;
        justify-content: end;
        gap: 16px;
        height: 45px;
      }
      div:first-child {
        width: 40px;
        img {
          width: 40px;
          height: 15px;
        }
        img:last-child {
          width: 34px;
          height: 14px;
        }
      }
      div:nth-child(2) {
        width: 51px;
        img {
          width: 46px;
          height: 17px;
        }
        img:last-child {
          width: 51px;
          height: 12px;
        }
      }
      div:nth-child(3) {
        width: 61px;
        img {
          width: 61px;
          height: 13px;
        }
        img:last-child {
          width: 57px;
          height: 15px;
        }
      }
      div:nth-child(4) {
        width: 80px;
        img {
          width: 40px;
          height: 9px;
          margin-bottom: 4px;
        }
        img:last-child {
          width: 80px;
          height: 12px;
          margin: 0;
        }
      }
    }
  }
}
.bankListWrap {
  margin-top: 10px;
  padding: 25px 15px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  .sectionHeader {
    padding: 10px 0;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: var(--theme-font-color);
  }
  .bankSelectWrap {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    height: 48px;
    overflow: hidden;
    position: relative;
    &::after {
      display: none;
    }
    &.validationError {
      border: 1px solid #ff5656;
    }
    select {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      appearance: none;
      padding-left: 10px;
      box-sizing: border-box;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: var(--theme-font-color);
    }

    img {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      right: 13px;
      transform: translateY(-50%);
      pointer-events: none;
    }
  }
  .depositorNameWrap {
    display: flex;
    flex-direction: column;
    & > div:first-child {
      padding: 11px 0;
      display: flex;
      gap: 1px;
      span:first-child {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: var(--theme-font-color);
      }
      span:last-child {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #ff5656;
      }
    }
    input {
      width: 100%;
      height: 48px;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      padding-left: 10px;
      box-sizing: border-box;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: var(--theme-font-color);
    }
  }
}

//
.refundInfoWrap {
  margin-top: 10px;
  padding: 25px 15px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  .sectionHeader {
    padding: 10px 0;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: #131c31;
  }
  .bankSelectWrap {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    height: 48px;
    overflow: hidden;
    position: relative;
    &::after {
      display: none;
    }
    &.validationError {
      border: 1px solid #ff5656;
    }
    select {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      appearance: none;
      padding-left: 10px;
      box-sizing: border-box;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: #131c31;
    }

    img {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      right: 13px;
      transform: translateY(-50%);
      pointer-events: none;
    }
  }
  .accountHolderWrap,
  .accountNumberWrap {
    display: flex;
    flex-direction: column;
    & > div:first-child {
      padding: 11px 0;
      display: flex;
      gap: 1px;
      span:first-child {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #131c31;
      }
      span:last-child {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #ff5656;
      }
    }
    input {
      width: 100%;
      height: 48px;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      padding-left: 10px;
      box-sizing: border-box;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #131c31;
    }
    &.accountHolderWrap {
      margin-bottom: 10px;
    }
  }
}
.buyBtnArea {
  width: 100%;
  height: 52px;
  .buyBtnWrap {
    width: 100%;
    height: 52px;
    background-color: #f6f7f9;
    &.fixedBtn {
      position: fixed;
      bottom: 64px;
      left: 21px;
      right: 21px;
      width: auto;
      // margin: 0
    }
    .buyBtn {
      width: 100%;
      height: 100%;
      font-weight: 600;
      font-size: 17px;
      line-height: normal;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--theme-color);
      border-radius: 8px;
      cursor: pointer;
    }
  }
}
.agreementList {
  padding: 16px 21px;
  li {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    & > div:first-child {
      height: 26px;
      display: flex;
      align-items: center;
      .selectArea {
        height: 100%;
        display: flex;
        align-items: center;
        gap: 5.5px;
        cursor: pointer;
        input[type='checkbox'] {
          -webkit-appearance: none;
          appearance: none;
          width: 15px;
          height: 15px;
          border: 1.5px solid #d9d9d9;
          border-radius: 3px;
          background-color: #f6f7f9;
          &.checked {
            background-color: #131c31;
            border: 1.5px solid #131c31;
            position: relative;
            &::before {
              content: '';
              display: block;
              width: 5px;
              height: 7px;
              border: solid #fff;
              border-width: 0 2px 2px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
              position: absolute;
              left: 2.5px;
              top: 0px;
            }
          }
        }
        span {
          font-weight: 500;
          font-size: 12px;
          line-height: normal;
          color: var(--theme-font-color);
        }
        .agreeHit {
          font-weight: 600;
          text-decoration: underline;
        }
      }
      & > span {
        margin-left: 4px;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        text-decoration-line: underline;
        color: rgba(19, 28, 49, 0.5);
        cursor: pointer;
      }
    }
    & > p {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: var(--theme-font-color);
      width: 100%;
      padding-left: 20px;
    }
  }
}
//공통
.label {
  .labelWrap {
    padding: 11px 0;
    display: flex;
    gap: 1px;
    & > span:first-child {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #ff5656;
    }
    & > span:last-child {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: rgba(19, 28, 49, 0.5);
    }
  }
  .inputWrap {
    position: relative;
    .reason {
      width: 100%;
      height: 200px;
      box-sizing: border-box;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      resize: none;
    }
    .inputUnit {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: rgba(19, 28, 49, 0.5);
      position: absolute;
      top: 50%;
      right: 17px;
      transform: translateY(-50%);
    }
    .normalInput {
      width: 100%;
      height: 48px;
      box-sizing: border-box;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      padding-left: 10px;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: var(--theme-font-color);
      &::placeholder {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: rgba(19, 28, 49, 0.2);
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type='number'] {
        -moz-appearance: textfield;
      }
    }
  }
  & > p {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    color: rgba(19, 28, 49, 0.5);
    padding: 7px 0;
  }
}

.phoneNumberLabel {
  .labelWrap {
    padding: 11px 0;
    display: flex;
    gap: 1px;
    & > span:first-child {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #ff5656;
    }
    & > span:last-child {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: rgba(19, 28, 49, 0.5);
    }
  }
  .phoneNumberInuptWrap {
    position: relative;
    .reason {
      width: 100%;
      height: 200px;
      box-sizing: border-box;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      resize: none;
    }
    .inputUnit {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: rgba(19, 28, 49, 0.5);
      position: absolute;
      top: 50%;
      right: 17px;
      transform: translateY(-50%);
    }
    .normalInput {
      width: 100%;
      height: 48px;
      box-sizing: border-box;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      padding-left: 10px;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: var(--theme-font-color);
      &::placeholder {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: rgba(19, 28, 49, 0.2);
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type='number'] {
        -moz-appearance: textfield;
      }
    }
  }
}

input,
textarea {
  outline: none;
}
textarea {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: var(--theme-font-color);
  padding: 11px 11.5px 9px;
  &::placeholder {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: rgba(19, 28, 49, 0.2);
  }
}
//공통

.addressModalWrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  .addressModal {
    position: absolute;
    top: 50%;
    left: 21px;
    right: 21px;
    transform: translateY(-50%);
    .modalHeader {
      background-color: var(--theme-color);
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 11px;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.agreementModalContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  max-width: 850px;
  background: rgba(0, 0, 0, 0.4);
  .modal {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 21px;
    right: 21px;
    background-color: white;
    h3 {
      padding: 36px 20px 10px;
    }
    .content {
      border: 1px solid rgba(0, 0, 0, 0.1);
      margin: 10px 20px 0;
      padding: 13px 18.5px 11px;
      font-weight: 300;
      font-size: 15px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.5);
      min-height: 291px;
      box-sizing: border-box;
    }
    .submit {
      margin-top: 40px;
      background: var(--theme-color);
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
.pcVersion {
  .header {
    img {
      display: none;
    }
    span {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
    }
  }
  .orderForm {
    display: flex;
    gap: 16px;
    position: relative;
    .left {
      width: 427px;
      flex-shrink: 0;
    }
    .right {
      width: 365px;
      position: sticky;
      top: 200px;
      height: fit-content;
    }
  }
  .label {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    &:last-child {
      padding-bottom: 0;
    }
    .labelWrap {
      width: 94px;
      padding: 15px 0;
    }
    .inputWrap {
      flex-grow: 1;
    }
  }
  .phoneNumberLabel {
    display: flex;
    margin-bottom: 10px;
    .phoneNumberInuptWrap {
    }
    .labelWrap {
      width: 91px;
      padding: 15px 0 !important;
    }
    .box {
      flex-grow: 1;
    }
  }

  .securityNumberLabel {
    display: flex;
    margin-bottom: 10px;
    .labelWrap {
      width: 91px;
      padding: 15px 0 !important;
    }
  }
  .reasonLabel {
    align-items: flex-start;
    padding-bottom: 0;
  }
  .addressTypeWrap {
    display: flex;
    & > div:first-child {
      padding-top: 15px !important;
      padding-bottom: 15px !important;
      width: 94px;
    }
    .customCheckBoxList {
      display: flex;
      flex-wrap: wrap;
      .customCheckBoxWrap {
        padding-top: 22px !important;
        padding-bottom: 10px !important;
      }
    }
  }
  .addressWrap {
    display: flex;
    padding-bottom: 20px;
    .addressLabel {
      width: 94px;
      padding: 15px 0 !important;
    }
    & > div:last-child {
      flex-grow: 1;
      .label {
        padding-bottom: 0 !important;
      }
    }
  }
}

.validationError,
.securityNumberInuptWrap.validationError {
  position: relative;
  .inputUnit {
    display: none;
  }
  &::after {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    right: 13px;
    transform: translateY(-50%);
    background: url('../../assets/error.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  input,
  textarea {
    border: 1px solid #ff5656 !important;
    &::placeholder {
      color: #ff5656 !important;
    }
  }
}
.selectArea.validationError {
  &::after {
    display: none;
  }
  span {
    color: #ff5656 !important;
  }
}
